body {
  font-family: "SF Pro SC", "SF Pro Text", "SF Pro Icons", PingFang SC, Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
}

blockquote {
  padding: 0.4rem;
}

blockquote p {
  margin: 0;
}

.top-nav a, .post-wrapper a {
  text-decoration: auto;
}

.post-wrapper time {
  font-weight: 200;
}

footer span {
  margin-right: 0.4rem;
  white-space: nowrap;
}

p, li {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.highlight {
  overflow-x: auto;
  background: none;
  font-family: San Francisco Mono, Monaco, "Consolas", "Lucida Console", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
}

pre:has(.language-mermaid) {
  text-align: center;
  background: none;
}

.language-mermaid {
  background: none;
}

.post-date {
  width: 7em;
  flex-shrink: 0;
}

.tags {
  background-color: white;
  color: #777777;
  padding: 2px 5px;
  border-radius: 2px;
  text-decoration: none;
}

hr.stylish {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80));
  margin: 5px 0;
}
